import React from 'react';
import styled from '@emotion/styled';

const NotFound = () => (
  <Wrapper>
    <div>
      <h1>Page Not Found</h1>
      <p>{`Oops, we couldn't find this page!`}</p>
    </div>
  </Wrapper>
);

export default NotFound;

const Wrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 50vh;
  flex: 1;
  div {
    text-align: center;
  }
`;
